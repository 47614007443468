<template>
  <div class="agile-list">
    <div class="ibox">
      <div class="ibox-content">
        <h3>{{ list.name }}</h3>
        <p class="small"><i class="fa fa-hand-o-up"></i> Drag references between list</p>
        <ul class="agile-list scroller" id="style-scroll">
          <draggable v-model="list.shipping_references" :options="{group: 'shipping_references'}" class="dragArea" @change="cardMoved">
            <card v-for="card in list.shipping_references" :card="card" :list="list"></card>
          </draggable>
          <!--
          <a v-if="!editing" v-on:click="startEditing">Add a Card</a>
          <textarea v-if="editing" ref="message" v-model="message" class="form-control"></textarea>
          <button v-if="editing" v-on:click="createCard" class="btn btn-primary">Add</button>
          <a v-if="editing" v-on:click="editing=false">Cancel</a>
          -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import card from 'components/card'
import Rails from "rails-ujs"

export default {
  components: { card, draggable },
  
  props: ["list"],

  data: function() {
    return {
      editing: false,
      message: ""
    }
  },

  methods: {
    startEditing: function() {
      this.editing = true,
      this.$nextTick(() => { this.$refs.message.focus() })
    },

    cardMoved: function(event) {
      const evt = event.added || event.moved
      if (evt == undefined) { return }

      const element = evt.element
      const list_index = window.store.state.lists.findIndex((list) => {
        return list.shipping_references.find((shipping_reference) => {
          return shipping_reference.id === element.id
        })
      })
      var data = new FormData
      data.append("shipping_reference[list_id]", window.store.state.lists[list_index].id)
      data.append("shipping_reference[position]", evt.newIndex + 1)

      Rails.ajax({
        url: `/en/shipping_references/${element.id}/move`,
        type: "PATCH",
        data: data,
        dataType: "json",
      })
    },

    createCard: function() {
      var data = new FormData
      data.append("shipping_reference[list_id]", this.list_id)
      data.append("shipping_reference[description]", this.message)
      Rails.ajax({
        url: "/en/shipping_references",
        type: "POST",
        data: data,
        dataType: "json",
        sucess: (data) => {
            this.message = ""
            this.$nextTick(() => { this.$refs.message.focus() })
        }
      })
    }
  }
}
</script>

<style scoped>
.dragArea {
  min-height: 10px;
}

.agile-list {
  list-style: none;
  margin: 0;
  border-radius: 3px;
  display: inline-block;
  margin-right: 20px;
  padding: 10px;
  vertical-align: top;
  width: 350px;
}

.scroller {
  width: 325px;
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  margin-right: -40px;
}

#style-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-scroll::-webkit-scrollbar
{
	width: 4px;
	background-color: #F5F5F5;
}

#style-scroll::-webkit-scrollbar-thumb
{
	background-color: #B0B0B0;
}
</style>
