<template>
  <draggable v-model="lists" :options="{group: 'lists'}" class="board dragArea" @end="listMoved">
  <list v-for="(list, index) in lists" :list="list"></list>

  <div class="agile-list">
    <a v-if="!editing" v-on:click="startEditing" class="btn btn-primary block full-width">Add a List</a>
    <textarea v-if="editing" ref="message" v-model="message" class="form-control"></textarea>
    <div class="mt-20">
      <button v-if="editing" v-on:click="createList" class="btn btn-primary btn-sm">Add</button>
      <a v-if="editing" v-on:click="editing=false">Cancel</a>
    </div>
  </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import list from 'components/list'
import Rails from "rails-ujs"

export default {
  components: { draggable, list },
  
  data: function() {
    return {
      editing: false,
      message: "",
    }
  },

  computed: {
    lists: {
      get() {
        return this.$store.state.lists
      },
      set(value) {
        this.$store.state.lists = value
      },
    },
  },

  methods: {
    listMoved: function(event) {
      var data = new FormData
      data.append("list[position]", event.newIndex + 1)

      Rails.ajax({
        url: `/en/lists/${this.lists[event.newIndex].id}/move`,
        type: "PATCH",
        data: data,
        dataType: "json",
      })
    },

    startEditing: function() {
      this.editing = true,
      this.$nextTick(() => { this.$refs.message.focus() })
    },

    createList: function() {
      var data = new FormData
      data.append("list[name]", this.message)

      Rails.ajax({
        url: "/en/lists",
        type: "POST",
        data: data,
        dataType: "json",
        success: (data) => {
          this.message = ""
          this.editing = false
        }
      })
    }
  }
}
</script>

<style scoped>
.dragArea {
  min-height: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.board {
  overflow-x: auto;
  white-space: nowrap;
}

.agile-list {
  list-style: none;
  margin: 0;
  border-radius: 3px;
  display: inline-block;
  margin-right: 20px;
  padding: 10px;
  vertical-align: top;
  width: 350px;
}
</style>
