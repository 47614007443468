<template>
  <div>
    <div class="card card-body">
      <div class="row">
        <li class="info-element agile-card col-md-12">
          <div class="row">
            <div class="col-lg-10">
              {{ card.customer_name }}<br>
              <!-- <a @click="editing=true">{{ card.number }}</a> -->
              <a data-disable-with="<i class='fa fa-spinner fa-spin'></i> Loading..." data-remote="true" :href="'/en/shipping_references/' + card.id">{{ card.number }}</a>
            </div>

            <div class="col-lg-2">
              <div class="btn-group pull-right">
                <button data-toggle="dropdown" class="btn btn-default btn-xs dropdown-toggle" aria-expanded="false">
                  <i class="fa fa-bars"></i>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="" :href="'/en/shipping_references/'+ card.id +'/edit'">Edit</a></li>
                  <li role="separator" class="divider"></li>
                  <li><a target="_blank" class="" :href="'/en/shipping_references/'+ card.id +'/print_pick_slip'">Print Pick Slip</a></li>
                  <li><a target="_blank" class="" :href="'/en/shipping_references/'+ card.id +'/order_picked_report'">Order Picked Report</a></li>
                  <li><a target="_blank" class="" :href="'/en/shipping_references/'+ card.id +'/print_packing_list'">Print Packing List</a></li>
                  <li><a target="_blank" class="" :href="'/en/shipping_references/'+ card.id +'/print_proforma_invoice'">Print Proforma Invoice</a></li>
                  <li><a target="_blank" class="" :href="'/en/shipping_references/'+ card.id +'/print_proforma_invoice'">Print Proforma Invoice</a></li>
                  <li><a target="_blank" class="" :href="'/en/shipping_references/'+ card.id +'/tally_out.xls'">Tally Out</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="agile-detail">
            <i class="fa fa-clock-o"></i> {{ card.created_at }}<br>
            <i class="fa fa-user" style="margin-top: 10px;"></i> {{ card.user_name }} - {{ card.driver }}<br>
            <i class="fa fa-tags"></i> 
              <span v-for="(value, key) in card.departments">
                <span v-if="value == 1">
                  <span class="badge badge-info">{{ key }}</span>
                </span>
                <span v-else-if="value == 0">
                  <span class="badge badge-primary">{{ key }}</span>
                </span>
              </span>
            <div class="pull-right">
              <span class="text-right">{{ card.location }}</span> | {{ card.shipvia }}
            </div>
          </div>
        </li>
      </div>
    </div>
  
    <!-- Modal -->
    <div class="modal inmodal fade" id="modal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
        </div>
      </div>
    </div>

    <div v-if='editing' class="modal-backdrop show"></div>

    <div v-if='editing' @click="closeModal" class="modal show" style="display: block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ card.number }}</h5>
          </div>
          <div class="modal-body">
            <input v-model="description" class="form-control"></input>
            <input v-model="customer" class="form-control"></input>
          </div>
          <div class="modal-footer">
            <button @click="save" type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rails from "rails-ujs"

export default {
    props: ['card', 'list'],
    data: function() {
      return {
        editing: false,
        number: this.card.number,
        customer: this.card.customer,
        description: this.card.description,
      }
    },

    methods: {
      closeModal: function(event) {
        if (event.target.classList.contains("modal")) { this.editing = false }
      },

      save: function() {
        var data = new FormData
        data.append("shipping_reference[number]", this.number)
        data.append("shipping_reference[descritpion]", this.description)

        Rails.ajax({
          url: `/en/shipping_references/${this.card.id}`,
          type: "PATCH",
          data: data,
          dataType: "json",
          success: (data) => {
              this.editing = false
          }
        })
      },
    }
}
</script>

<style>
  .agile-card {
    background: #FAFAFB;
    border: 1px solid #e7eaec;
    margin: 0 0 10px 0;
    padding: 10px;
    border-radius: 2px;
    width: 300px;

  }
  .agile-list li:hover {
    cursor: pointer;
    background: #fff;
  }
  .agile-list li.warning-element {
    border-left: 3px solid #f8ac59;
  }
  .agile-list li.danger-element {
    border-left: 3px solid #ed5565;
  }
  .agile-list li.info-element {
    border-left: 3px solid #1c84c6;
  }
  .agile-list li.success-element {
    border-left: 3px solid #1ab394;
  }
  .agile-detail {
    margin-top: 5px;
    font-size: 12px;
  }
</style>
